<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <a-row :gutter="[16, 16]">
                  <a-col :span="8">
                    <div class="border rounded card-body">
                      <div class="font-size-24 font-weight-bold text-primary mb-2">{{thousandPrice(1279500000)}}</div>
                      <div class="font-size-12 text-uppercase">Total Pengeluaran</div>
                    </div>
                  </a-col>
                  <a-col :span="8">
                    <div class="border rounded card-body">
                      <div class="font-size-24 font-weight-bold text-warning text-uppercase mb-2">25 Jan 2021</div>
                      <div class="font-size-12 text-uppercase">Tanggal Pembayaran Gaji</div>
                    </div>
                  </a-col>
                <a-col :span="8">
                  <div class="border rounded card-body">
                    <div class="font-size-24 font-weight-bold text-danger text-uppercase mb-2">13 Pegawai</div>
                    <div class="font-size-12 text-uppercase">Pinjaman Aktif</div>
                  </div>
                </a-col>
              </a-row>
              <a-row :gutter="[16,16]" class="mt-3">
                <a-col :span="16">
                  <div class="card-body rounded border">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <div class="font-size-20 text-uppercase font-weight-bold text-dark">Laporan Pengeluaran</div>
                      </div>
                      <div>
                        <a-select
                          size="large"
                          default-value="2021"
                          style="width:120px"
                        >
                          <a-select-option key="2019">2019</a-select-option>
                          <a-select-option key="2020">2020</a-select-option>
                          <a-select-option key="2021">2021</a-select-option>
                        </a-select>
                      </div>
                    </div>
                    <vue-chartist
                      class="height-300 mt-4"
                      type="Bar"
                      :data="ChartistData"
                      :options="ChartistOptions"
                    />
                    <div class="d-flex justify-content-center align-items-center mt-3">
                      <span class="mr-4">
                        <span class="kit__utils__donut kit__utils__donut--primary"></span>
                        Gaji Pokok
                      </span>
                      <span class="mr-2">
                        <span class="kit__utils__donut kit__utils__donut--success"></span>
                        Tunjangan
                      </span>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="card-body rounded border">
                    <div class="font-size-20 text-uppercase font-weight-bold text-dark">Transaksi Terakhir</div>
                    <div class="mt-3">
                      <div>
                        <div class="bg-success text-white rounded px-3 py-1 mb-1">Hari Ini - 22 Jan 2021</div>
                        <div class="table-responsive">
                          <table class="table table-borderless text-gray-6 mb-0">
                            <tbody>
                              <tr>
                                <td>Tidak Ada Transaksi</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        <div class="bg-light rounded text-gray-6 px-3 py-1 mb-1">25 Dec 2020</div>
                        <div class="table-responsive">
                          <table class="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td>Gaji Pegawai</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(3100000)}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Pinjaman</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(4000000)}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Gaji Pegawai</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(2500000)}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Gaji Pegawai</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(2000000)}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Pinjaman</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(5000000)}}</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Pinjaman</td>
                                <td class="text-right align-items-center">
                                  <span class="text-danger font-weight-bold">Rp. {{thousandPrice(6000000)}}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </template>
            <template v-if="menuActive === 3">
              <div class="d-flex align-items-center">
                <div>
                  <a-select
                    size="large"
                    default-value="January"
                    class="mr-3"
                  >
                  <a-select-option key="January">January</a-select-option>
                  <a-select-option key="February">February</a-select-option>
                  <a-select-option key="March">March</a-select-option>
                  <a-select-option key="April">April</a-select-option>
                </a-select>
                <a-select
                  size="large"
                  default-value="2021"
                >
                  <a-select-option key="2019">2019</a-select-option>
                  <a-select-option key="2020">2020</a-select-option>
                  <a-select-option key="2021">2021</a-select-option>
                </a-select>
                </div>
                <div class="ml-auto">
                  <a-button size="large"><a-icon type="sync" />Refresh</a-button>
                </div>
              </div>
              <a-row :gutter="[16, 16]" class="mt-3">
                  <a-col :span="6">
                    <div class="border rounded card-body">
                      <div class="font-size-24 font-weight-bold text-warning mb-2">25 JAN 2021</div>
                      <div class="font-size-12 text-uppercase">Tanggal Pembayaran</div>
                    </div>
                  </a-col>
                <a-col :span="6">
                  <div class="border rounded card-body">
                    <div class="font-size-24 font-weight-bold text-primary mb-2">{{thousandPrice(127950000)}}</div>
                    <div class="font-size-12 text-uppercase">Total Transaksi</div>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="border rounded card-body">
                    <div class="font-size-24 font-weight-bold text-success text-uppercase mb-2">98 PEGAWAI</div>
                    <div class="font-size-12 text-uppercase">Sudah Dibayar</div>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="border rounded card-body">
                    <div class="font-size-24 font-weight-bold text-danger text-uppercase mb-2">63 PEGAWAI</div>
                    <div class="font-size-12 text-uppercase">Belum Dibayar</div>
                  </div>
                </a-col>
              </a-row>
              <a-row :gutter="[16,16]" class="mt-3">
                <a-col :span="12" v-for="(item, index) in statistikGaji" :key="index">
                  <div class="border rounded pt-2 px-4 pb-4">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="pr-3">
                        <h2 class="font-size-18 font-weight-bold mb-1 text-dark">Pembayaran Gaji</h2>
                        <p class="font-size-15 mb-3">{{item.type}}</p>
                      </div>
                      <div :class="item.percent > 75 ? 'text-success' : item.percent > 40 ? 'text-warning' : 'text-danger'" class="font-weight-bold font-size-24">
                        Rp. {{thousandPrice(item.price)}}
                      </div>
                    </div>
                    <div class="mb-3">
                      <a-progress
                        type="line"
                        :percent="item.percent"
                        :showInfo="false"
                        :strokeWidth="12"
                        :strokeColor="item.percent > 75 ? '#46be8a' : item.percent > 40 ? '#FBAC13' : '#F5212E'"
                      />
                    </div>
                    <div class="d-flex text-gray-5 justify-content-between font-size-14">
                      <span class="text-uppercase">Terbayar {{item.percent}}%</span>
                      <span class="text-uppercase">dari rp. {{thousandPrice(item.total)}}</span>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </template>
            <template v-if="menuActive === 4">
              <PencatatanGaji />
            </template>
            <template v-else-if="menuActive === 5">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <a-input size="large" placeholder="Cari Pegawai..." />
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="January"
                  >
                    <a-select-option key="January">January</a-select-option>
                    <a-select-option key="February">February</a-select-option>
                    <a-select-option key="March">March</a-select-option>
                    <a-select-option key="April">April</a-select-option>
                  </a-select>
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="2021"
                  >
                    <a-select-option key="2019">2019</a-select-option>
                    <a-select-option key="2020">2020</a-select-option>
                    <a-select-option key="2021">2021</a-select-option>
                  </a-select>
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="All"
                    style="width:180px;"
                  >
                    <a-select-option key="All">Semua Pegawai</a-select-option>
                    <a-select-option key="TK">KB-TK</a-select-option>
                    <a-select-option key="SD">SD</a-select-option>
                    <a-select-option key="SMP">SMP</a-select-option>
                    <a-select-option key="Karyawan">Karyawan</a-select-option>
                  </a-select>
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="All"
                    style="width:160px;"
                  >
                    <a-select-option key="All">Semua Status</a-select-option>
                    <a-select-option key="Sudah Dibayar">Sudah Dibayar</a-select-option>
                    <a-select-option key="Belum Dibayar">Belum Dibayar</a-select-option>
                  </a-select>
                </div>
                <div class="ml-auto">
                  <a-modal
                  title="Cetak Slip Gaji"
                  :visible="visibleModalExport"
                  @cancel="handleVisibleModalExport"
                >
                  <a-form>
                    <a-form-item label="Tipe Pegawai">
                      <a-select
                        size="large"
                        default-value="All"
                      >
                        <a-select-option key="All">Semua Pegawai</a-select-option>
                        <a-select-option key="TK">KB-TK</a-select-option>
                        <a-select-option key="SD">SD</a-select-option>
                        <a-select-option key="SMP">SMP</a-select-option>
                        <a-select-option key="Employee">Karyawan</a-select-option>
                      </a-select>
                    </a-form-item>
                    <div class="row">
                      <div class="col-lg-6">
                        <a-form-item label="Month">
                          <a-select
                            size="large"
                            default-value="January"
                          >
                            <a-select-option key="January">January</a-select-option>
                            <a-select-option key="February">February</a-select-option>
                            <a-select-option key="March">March</a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>
                      <div class="col-lg-6">
                        <a-form-item label="Year">
                          <a-select
                            size="large"
                            default-value="2021"
                          >
                            <a-select-option key="2019">2019</a-select-option>
                            <a-select-option key="2020">2020</a-select-option>
                            <a-select-option key="2021">2021</a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>
                    </div>
                  </a-form>
                  <template slot="footer">
                  <a-button key="back" @click="handleVisibleModalExport">
                  Kembali
                  </a-button>
                  <a-button key="submit" type="primary" :loading="confirmLoadingModalAttendance" @click="handleOkModalExport">
                  Cetak
                  </a-button>
              </template>
                </a-modal>
                  <a-button @click.prevent="handleVisibleModalExport" type="danger" size="large">
                    <a-icon type="export" /> Cetak
                  </a-button>
                </div>
              </div>
              <div class="mt-4">
                <a-table :columns="slipColumns" :data-source="slipData" bordered>
                  <template slot="title">
                    UNIT (SEMUA)
                  </template>
                  <div slot="name" slot-scope="value" class="d-flex align-items-center">
                    <!-- <a-avatar class="bg-primary mr-2">
                      J
                    </a-avatar> -->
                    {{value}}
                  </div>
                  <span slot="status" slot-scope="value">
                    <a-tag
                      :color="value === 'Sudah Dibayar' ? 'green' : value === 'Belum Dibayar' ? 'volcano' : ''"
                    >
                      {{ value.toUpperCase() }}
                    </a-tag>
                  </span>
                  <div slot="aksi">
                    <a-button size="large" class="mr-3"><a-icon type="container" />Lihat Slip Gaji</a-button>
                    <!-- <a-button size="large" type="danger"><a-icon type="file-pdf" />Export Payslip</a-button> -->
                  </div>
                </a-table>
              </div>
            </template>
            <template v-else-if="menuActive === 6">
              <div class="d-flex align-items-center">
                <div class="mr-3">
                  <a-input size="large" placeholder="Cari Pegawai..." />
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="All"
                    style="width:180px;"
                  >
                    <a-select-option key="All">Semua Pegawai</a-select-option>
                    <a-select-option key="TK">KB-TK</a-select-option>
                    <a-select-option key="SD">SD</a-select-option>
                    <a-select-option key="SMP">SMP</a-select-option>
                    <a-select-option key="Karyawan">Karyawan</a-select-option>
                  </a-select>
                </div>
                <div class="mr-3">
                  <a-select
                    size="large"
                    default-value="All"
                    style="width:160px;"
                  >
                    <a-select-option key="All">Semua Status</a-select-option>
                    <a-select-option key="Sudah Lunas">Sudah Lunas</a-select-option>
                    <a-select-option key="Belum Lunas">Belum Lunas</a-select-option>
                  </a-select>
                </div>
                <div class="ml-auto">
                  <a-button class="mr-3" size="large" type="primary"><a-icon type="plus" /> Tambah Pinjaman </a-button>
                  <a-modal
                  title="Cetak Laporan Pinjaman"
                  :visible="visibleModalExport"
                  @cancel="handleVisibleModalExport"
                >
                  <a-form>
                    <a-form-item label="Tipe Pegawai">
                      <a-select
                        size="large"
                        default-value="All"
                      >
                        <a-select-option key="All">Semua Pegawai</a-select-option>
                        <a-select-option key="TK">KB-TK</a-select-option>
                        <a-select-option key="SD">SD</a-select-option>
                        <a-select-option key="SMP">SMP</a-select-option>
                        <a-select-option key="Employee">Karyawan</a-select-option>
                      </a-select>
                    </a-form-item>
                    <div class="row">
                      <div class="col-lg-6">
                        <a-form-item label="Month">
                          <a-select
                            size="large"
                            default-value="January"
                          >
                            <a-select-option key="January">January</a-select-option>
                            <a-select-option key="February">February</a-select-option>
                            <a-select-option key="March">March</a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>
                      <div class="col-lg-6">
                        <a-form-item label="Year">
                          <a-select
                            size="large"
                            default-value="2021"
                          >
                            <a-select-option key="2019">2019</a-select-option>
                            <a-select-option key="2020">2020</a-select-option>
                            <a-select-option key="2021">2021</a-select-option>
                          </a-select>
                        </a-form-item>
                      </div>
                    </div>
                  </a-form>
                  <template slot="footer">
                  <a-button key="back" @click="handleVisibleModalExport">
                  Kembali
                  </a-button>
                  <a-button key="submit" type="primary" :loading="confirmLoadingModalAttendance" @click="handleOkModalExport">
                  Cetak
                  </a-button>
              </template>
                </a-modal>
                  <a-button @click.prevent="handleVisibleModalExport" type="danger" size="large">
                    <a-icon type="export" /> Cetak
                  </a-button>
                </div>
              </div>
              <div class="mt-4">
                <a-table :columns="pinjamanColumns" :data-source="pinjamanData" bordered>
                  <template slot="title">
                    UNIT (SEMUA)
                  </template>
                  <div slot="name" slot-scope="value" class="d-flex align-items-center">
                    <!-- <a-avatar class="bg-primary mr-2">
                      J
                    </a-avatar> -->
                    {{value}}
                  </div>
                  <span slot="progress" slot-scope="value">
                    <a-progress :percent="value" status="active" />
                  </span>
                  <span slot="status" slot-scope="value">
                    <a-tag
                      :color="value === 'Sudah Lunas' ? 'green' : value === 'Belum Lunas' ? 'volcano' : ''"
                    >
                      {{ value.toUpperCase() }}
                    </a-tag>
                  </span>
                  <div slot="aksi">
                    <a-button size="large" class="mr-3"><a-icon type="container" />Detail</a-button>
                    <!-- <a-button size="large" type="danger"><a-icon type="file-pdf" />Export Payslip</a-button> -->
                  </div>
                </a-table>
              </div>
            </template>
            <!-- <template v-else-if="menuActive === 7">
              <slip-gaji :configSchool="configSchool" :slipGaji="dataSlipGaji" />
            </template> -->
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="font-size-18">Payrolls</h2>
          </div>
        </div>
      </div>
      <div class="card-body mt-3">
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <a-select
              size="large"
              default-value="January"
            >
              <a-select-option key="January">January</a-select-option>
              <a-select-option key="February">February</a-select-option>
              <a-select-option key="March">March</a-select-option>
              <a-select-option key="April">April</a-select-option>
            </a-select>
          </div>
          <div class="mr-3">
            <a-select
              size="large"
              default-value="2021"
            >
              <a-select-option key="2019">2019</a-select-option>
              <a-select-option key="2020">2020</a-select-option>
              <a-select-option key="2021">2021</a-select-option>
            </a-select>
          </div>
          <div class="mr-3">
            <a-select
              size="large"
              default-value="All"
              style="width:120px;"
            >
              <a-select-option key="All">All Status</a-select-option>
              <a-select-option key="Completed">Completed</a-select-option>
              <a-select-option key="On Progress">On Progress</a-select-option>
            </a-select>
          </div>
          <div class="ml-auto">
            <a-button type="primary" size="large">
              <a-icon type="plus" /> Add Payroll
            </a-button>
          </div>
        </div>
        <div class="mt-4">
          <a-table :columns="columns" :data-source="data" bordered>
            <div slot="nama" slot-scope="value" class="d-flex align-items-center">
              {{value}}
            </div>
            <span slot="status" slot-scope="value">
              <a-tag
                :color="value === 'Completed' ? 'green' : value === 'On Progress' ? 'blue' : ''"
              >
                {{ value.toUpperCase() }}
              </a-tag>
            </span>
            <div slot="aksi">
              <a-button @click="toPayrollPeriod" size="large"><a-icon type="file-search" />Check Payroll</a-button>
            </div>
          </a-table>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import config from '@/config'
import Menu from '@/components/app/MenuV2'
import thousandPrice from '@/helpers/thousandPrice'
import moment from 'moment'
import VueChartist from 'v-chartist'
const PencatatanGaji = () => import('@/components/app/AdminKeuangan/PencatatanGaji')
// const SlipGaji = () => import('@/components/app/Shared/SlipGaji')
const menus = [
  {
    key: 1,
    menu: 'Statistik',
    icon: 'line-chart',
  },
  {
    key: 2,
    sub: true,
    menu: 'Penggajian',
    children: [
      {
        key: 3,
        menu: 'Ringkasan Gaji',
      },
      {
        key: 4,
        menu: 'Pencatatan Gaji',
      },
      {
        key: 5,
        menu: 'Slip Gaji',
      },
    ],
  },
  {
    key: 6,
    menu: 'Pinjaman',
    icon: 'wallet',
  },
  // {
  //   key: 7,
  //   menu: 'Pengaturan',
  //   icon: 'wallet',
  // },
]

const ChartistData = {
  labels: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
  ],
  series: [
    [
      10725000,
      11825000,
      11625000,
      10005000,
    ],
    [
      12725000,
      12825000,
      12625000,
      11005000,
    ],
  ],
}
const ChartistOptions = {
  fullWidth: true,
  showArea: false,
  chartPadding: {
    right: 30,
    left: 25,
  },
}

const statistikGaji = [
  {
    type: 'KB-TK',
    price: 58925000,
    percent: 80,
    total: 65250000,
  },
  {
    type: 'SD',
    price: 25265000,
    percent: 50,
    total: 50153000,
  },
  {
    type: 'SMP',
    price: 8250000,
    percent: 12,
    total: 68000000,
  },
  {
    type: 'Karyawan',
    price: 50125000,
    percent: 100,
    total: 50125000,
  },
]

const gajiColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
  },
  {
    title: 'Gaji yang Diterima (Rp)',
    dataIndex: 'gaji',
    key: 'gaji',
    align: 'right',
    scopedSlots: { customRender: 'gaji' },
  },
  {
    title: 'Status Gaji',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'aksi',
    dataIndex: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const gajiData = [
  {
    key: 1,
    nama: 'Supriadi, SE',
    gaji: 10250000,
    jabatan: 'Wakil Ketua LPI',
    status: 'Sudah Dibayar',
  },
  {
    key: 2,
    nama: 'Nurlailah, S.Pd',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah KB-TK',
    status: 'Belum Dibayar',
  },
  {
    key: 3,
    nama: 'Aang Kunaifi, S.Sos.I',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah SD',
    status: 'Belum Dibayar',
  },
  {
    key: 4,
    nama: 'Ade Badar Taufiq, ST',
    gaji: 9250000,
    jabatan: 'Kepala Sekolah SMP',
    status: 'Sudah Dibayar',
  },
]

const slipColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
  },
  {
    title: 'Status Gaji',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'aksi',
    dataIndex: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const slipData = [
  {
    key: 1,
    nama: 'Supriadi, SE',
    unit: 'Employee',
    jabatan: 'Wakil Ketua LPI',
    status: 'Sudah Dibayar',
  },
  {
    key: 2,
    nama: 'Nurlailah, S.Pd',
    unit: 'KB-TK',
    jabatan: 'Kepala Sekolah KB-TK',
    status: 'Belum Dibayar',
  },
  {
    key: 3,
    nama: 'Aang Kunaifi, S.Sos.I',
    unit: 'SD',
    jabatan: 'Kepala Sekolah SD',
    status: 'Belum Dibayar',
  },
  {
    key: 4,
    nama: 'Ade Badar Taufiq, ST',
    unit: 'SMP',
    jabatan: 'Kepala Sekolah SMP',
    status: 'Sudah Dibayar',
  },
]

const pinjamanColumns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Jabatan',
    dataIndex: 'jabatan',
    key: 'jabatan',
  },
  {
    title: 'Progress Cicilan',
    dataIndex: 'progress',
    key: 'progress',
    scopedSlots: { customRender: 'progress' },
  },
  {
    title: 'Status Pinjaman',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'aksi',
    dataIndex: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const pinjamanData = [
  {
    key: 1,
    nama: 'Supriadi, SE',
    jabatan: 'Wakil Ketua LPI',
    progress: 100,
    status: 'Sudah Lunas',
  },
  {
    key: 2,
    nama: 'Nurlailah, S.Pd',
    jabatan: 'Kepala Sekolah KB-TK',
    progress: 50,
    status: 'Belum Lunas',
  },
  {
    key: 3,
    nama: 'Aang Kunaifi, S.Sos.I',
    jabatan: 'Kepala Sekolah SD',
    progress: 15,
    status: 'Belum Lunas',
  },
  {
    key: 4,
    nama: 'Ade Badar Taufiq, ST',
    jabatan: 'Kepala Sekolah SMP',
    progress: 100,
    status: 'Sudah Lunas',
  },
]

const dataSlipGaji = {
  pegawai: {
    nik: 811015161,
    nama: 'Ade Badar',
    jenis_kelamin: 'Laki-Laki',
    status_pegawai: 'Tetap',
    jabatan_pegawais: [
      {
        jabatan: {
          nama: 'Kepala Sekolah',
          jabatan_tunjangan: {
            tunjangan_fungsional: 7,
            tunjangan_jabatan: 0,
            tunjangan_lain: 0,
            tunjangan_operasional: 0,
            tunjangan_transportasi: 23000,
            tunjangan_makan: 23000,
          },
        },
      },
      // {
      //   jabatan: {
      //     nama: 'Kepala Sekolah',
      //     jabatan_tunjangan: {
      //       tunjangan_fungsional: 45,
      //       tunjangan_jabatan: 45,
      //       tunjangan_lain: 0,
      //       tunjangan_operasional: 500000,
      //       tunjangan_transportasi: 45000,
      //       tunjangan_makan: 45000,
      //     },
      //   },
      // },
      // {
      //   jabatan: {
      //     nama: 'Guru Kelas VII',
      //     jabatan_tunjangan: {
      //       tunjangan_fungsional: 7,
      //       tunjangan_jabatan: 0,
      //       tunjangan_lain: 0,
      //       tunjangan_operasional: 0,
      //       tunjangan_transportasi: 45000,
      //       tunjangan_makan: 45000,
      //     },
      //   },
      // },
    ],
    unit: {
      nama: 'SMP',
    },
    golongan: {
      nama: '3C',
      gaji_pokok: 1100000,
    },
    // marital_tunjangan: {
    //   status_marital: 'M2',
    //   laki_laki: 6,
    //   perempuan: 3,
    //   tunjangan_keluarga: 12,
    //   tunjangan_kepala_keluarga: 10,
    // },
    marital_tunjangan: null,
  },
  rekap_absen: {
    periode: {
      tanggal_awal: '2021-02-26',
      tanggal_akhir: '2021-03-25',
    },
    rincian_gaji: {
      tanggal_pembayaran_gaji: '2021-03-17 12:00',
      total_penghasilan: 0,
      total_pengurangan: 0,
      total_gaji: 0,
    },
    sakit: 0,
    izin: 0,
    alfa: 0,
    cuti: 0,
    libur: 1,
    terlambat: 0,
    pulang_lebih_awal: 0,
    hari_kerja_normal: 22,
    jumlah_hari_kerja: 14,
    tambahan_hari_kerja: 0,

  },
}

export default {
  name: 'Finance',
  components: {
    Menu,
    VueChartist,
    PencatatanGaji,
    // SlipGaji,
  },
  data() {
    return {
      menus,
      ChartistData,
      ChartistOptions,
      statistikGaji,
      gajiColumns,
      gajiData,
      slipColumns,
      slipData,
      pinjamanColumns,
      pinjamanData,
      thousandPrice,
      dataSlipGaji,
      endOpen: false,
      visibleModalExport: false,
      confirmLoadingModalAttendance: false,
      startAt: '',
      endAt: '',
      configSchool: '',
      menuActive: 1,
      today: moment(),
      periodData: 'This Month',
    }
  },
  mounted() {
    this.configSchool = config.school
  },
  methods: {
    handleMenuClick(e) {
      // console.log(e)
      if (e.key === 'payroll') return this.runPayroll()
      else if (e.key === 'sync') return this.syncData()
    },
    runPayroll() {
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>
            <div>Apa anda yakin untuk mengirimkan data rekap pada bagian keuangan?</div>
            <small>*Selanjutnya akan dilakukan koordinasi pengecekan terhadap bagian keuangan dan SDM.</small>
          </div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.$store.dispatch('adminHR/FINALIZE_ABSENT_RECAP', { idPeriod: this.selectMonth })
            .then(isSuccess => {
              if (isSuccess) {
                this.$notification.success({
                  message: 'Sukses.',
                  description: 'Berhasil mengubah status periode menjadi pengecekan',
                })
              } else {
                this.$notification.error({
                  message: 'Maaf',
                  description: 'Terjadi kesalahan pada internal server.',
                })
              }
              this.fetchDataTable({ search: this.searchData })
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Lanjutkan',
        cancelText: 'Batalkan',
      })
    },
    syncData() {
      this.loadingTable = true
      this.$store.dispatch('absensiKaryawan/SYNC_ABSENT_RECAP', { idPeriod: this.selectMonth })
        .then(_ => {
          this.fetchDataTable({ search: this.searchData })
        })
    },
    searchPegawai(e) {
      // console.log(value)
      this.searchData = e.target.value
      this.fetchDataTable({ search: this.searchData })
    },
    onSelectChange(status) {
      if (status === 'tahun') {
        const dataMonth = this.months.filter(month => month.tahun === this.selectYear)
        this.selectMonth = dataMonth[0].id
      }
      this.fetchDataTable()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
        search: this.searchData,
      })
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('adminHR/FETCH_ALL_PRESENCE_RECAP', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, idPeriod: this.selectMonth, unit: this.selectUnit })
        .then(({ data, total }) => {
          // console.log(res)
          this.loadingTable = false
          const pagination = { ...this.pagination }
          this.pagination.total = total
          pagination.total = total
          this.pagination = pagination
          // console.log(res)
          this.recapitulationDataTable = data.map(el => {
            return {
              key: el.id,
              id: el.id,
              nama: el.nama,
              ...el.rekap_absens[0],
              unit: el.unit ? el.unit.nama : null,
              jthk: el.rekap_absens[0].jumlah_hari_kerja + el.rekap_absens[0].tambahan_hari_kerja,
            }
          })
        })
    },
    toggleModalExport() {
      this.visibleModalExportRecapitulation = !this.visibleModalExportRecapitulation
    },
    periodOnChange(value) {
      console.log('ok')
      this.periodData = value
    },
    handleOkModalExport() {
      console.log('export')
    },
    menuChange(payload) {
      this.menuActive = payload
    },
    toPayrollPeriod() {
      this.$router.push({ name: 'Payroll Period Admin HR' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Keuangan Admin HR'],
      })
    },
    disabledStartDate(startValue) {
      const endValue = this.endAt
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startAt
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleVisibleModalExport() {
      this.visibleModalExport = !this.visibleModalExport
    },
    dateOnChange(e) {
      console.log(e)
    },
  },
  computed: {
    menuName() {
      let menu = ''
      this.menus.forEach(el => {
        if (el.sub) {
          const find = el.children.filter(elChildren => elChildren.key === this.menuActive)[0]
          if (find !== undefined) {
            menu = find.menu
          }
        } else {
          if (el.key === this.menuActive) {
            menu = el.menu
          }
        }
      })
      return menu
      // const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      // const { menu } = this.menus.filter(el => el.children ? el.children.filter(elChildren => elChildren.key === this.menuActive)[0] : el.key === this.menuActive)[0]
      // return menu
    },
  },
}
</script>

<style>

</style>
